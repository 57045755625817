import { Component, Ref, Vue } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import zrDoc, { ZRDocument } from '@/shared/model/zrDocument';
import D4yEdit from '@/components/d4y-edit/d4y-edit.vue';
import { CONST } from '@/shared/utils/Constants';
import { ROUTES } from '@/router/routesEnum';
import DateUtils from '@/shared/utils/DateUtils';
import DateField from '@/components/_common/date-field/DateField.vue';
import StatusDialog from '@/views/home/zr-documents/status-dialog/status-dialog.vue';
import DocumentPreview from '@/views/home/document-preview/document-preview.vue';
import html2canvas from 'html2canvas';
import GeneralUtils, { debounceAsync } from '@/shared/utils/generalUtils';
import NumberUtils from '@/shared/utils/numberUtils';
import SupplierTooltip from '../search-form/supplier-tooltip/supplier-tooltip.vue';

const name = 'zr-document';
const logger = new Logger(name);
const zrDocumentModule = namespace('zrDocument');
const authModule = namespace('auth');

const delay = GeneralUtils.delay; // delay function

@Component({
  name: name,
  components: {
    D4yEdit,
    DateField,
    StatusDialog,
    DocumentPreview,
    SupplierTooltip,
  },
})
export default class ZrDocument extends Vue {
  @Ref('observer-add-edit-zrDocument-form')
  private addEditZrDocumentForm!: any;

  @authModule.Getter('isUserInAzureAdminGroup')
  private isUserInAzureAdminGroup!: any;
  @authModule.Getter('isUserSupport')
  private isUserSupport!: any;
  @authModule.Getter('isUserGsUser')
  private isUserGsUser!: any;
  @authModule.Getter('isUserHasRightsForDocuments')
  private isUserHasRightsForDocuments!: any;

  @zrDocumentModule.Action('getZRDocument')
  private actionGetZRDocument!: any;
  @zrDocumentModule.Action('updateZRDocument')
  private actionUpdateZRDocument!: any;
  @zrDocumentModule.Action('updateZRDocumentStatus')
  private actionUpdateZRDocumentStatus!: any;
  @zrDocumentModule.Action('updateZRDocumentCommission')
  private actionUpdateZRDocumentCommission!: any;
  @zrDocumentModule.Action('getZrDocumentsPdfReportView')
  private actionGetZrDocumentsPdfReportView!: any;
  @zrDocumentModule.Getter('getZRDocument')
  private getZRDocument!: any;
  @zrDocumentModule.Getter('getZRDocumentsItems')
  private documents!: Array<any>;

  // @zrDocumentModule.Action('getSaveList') private actionGetSaveList!: any;
  // @saveListManagement.Action('updateSaveList')
  // private actionUpdateSaveList!: any;

  private isRecordLoaded = false;
  private isPdfLoaded = false;
  private isShowBackupBelegeImage = false;
  private isShowBackupRechnungImage = false;
  private isGeneratingPdfReport = false;

  private belegeType = 'Belege';
  private rechnungType = 'Rechnung';

  get zrDocumentId() {
    return this.$route.params['zrDocumentId'];
  }

  get isZrDocumentCreate() {
    return this.zrDocumentId == CONST.emptyGuid || !this.zrDocumentId;
  }

  // when change record via `next/previous` button the `mounted` method will NOT be called
  async mounted() {
    if ((!this.isUserInAzureAdminGroup && !this.isUserGsUser) || !this.isUserHasRightsForDocuments) {
      this.goToHome();
    }

    if (this.isZrDocumentCreate) {
      logger.error(`Creation of 'ZrDocument' is not supported!`);
    } else {
      await this.getZrDocument();
    }
  }

  showBackupImage(documentType: string) {
    if (documentType == this.belegeType) {
      this.isShowBackupBelegeImage = true;
    } else if (documentType == this.rechnungType) {
      this.isShowBackupRechnungImage = true;
    }
    this.isPdfLoaded = true; // ignore PDF loading
  }

  private originalCommission = '';
  async getZrDocument() {
    this.isRecordLoaded = false; // change loading flag when change `zrDocument` via `next/previous` buttons
    await this.actionGetZRDocument(this.zrDocumentId)
      .then((response: any) => {
        console.log('zre Document response :>> ', response);
        this.zrDocument = Object.assign({}, response);

        this.titleInfo = this.zrDocument.name;
        this.originalCommission = response.kommission_Nummer;
      })
      .then(() => {
        this.isRecordLoaded = true;
      });
  }
  private zrDocument: any = zrDoc.parse({});

  isLoading = false;
  titleInfo = 'empty';

  get isEmptySaveList() {
    return this.zrDocument.id == '';
  }

  get image() {
    return {
      src: 'gs_details.svg',
      header: '',
      width: 230,
      height: 210,
    };
  }

  // private imageReceiptVar: {
  //   src: string;
  //   header?: string;
  //   height: number;
  //   width: number;
  //   small?: boolean;
  // } = {
  //   src: 'belege_stub_big.png',
  //   height: this.dynamicImageSize.height,
  //   width: this.dynamicImageSize.width,
  // };

  get imageReceipt() {
    return {
      src: 'belege_stub_big.png',
      height: this.dynamicImageSize.height,
      width: this.dynamicImageSize.width,
    };
  }

  get isNeuStatus() {
    return this.zrDocument?.status_Freigabe == 1;
  }

  get dynamicImageSize() {
    if (this.$vuetify.breakpoint.xlOnly) {
      return { height: 610, width: 450 };
    } else if (this.$vuetify.breakpoint.lgOnly && this.$vuetify.breakpoint.width > 1580) {
      return { height: 510, width: 350 };
    } else if (this.$vuetify.breakpoint.lgOnly && this.$vuetify.breakpoint.width < 1580) {
      return { height: 450, width: 300 };
    } else if (this.$vuetify.breakpoint.mdOnly) {
      return { height: 410, width: 250 };
    }

    return { height: 515, width: 350 };
  }

  get commissionChanged() {
    return (
      (this.originalCommission || this.originalCommission == null) &&
      this.originalCommission != this.zrDocument.kommission_Nummer &&
      this.zrDocument.kommission_Nummer?.length > 0
    );
  }

  get notices() {
    // return [
    //   {
    //     belegtyp: this.$t('cancel_temp'),
    //     reason: this.$t('change_temp'),
    //     date: '12.08.2023',
    //     brutto_betrag: '250,00',
    //   },
    // ];
    return this.zrDocument?.invoices;
  }

  get hasRecords() {
    return this.zrDocument?.invoices?.length > 0;
  }

  get noticeHeaders() {
    let headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
      align?: string;
    }[] = [
      { text: this.$t('busy_type'), value: 'belegTyp', sortable: false, width: '209' },
      { text: this.$t('date'), value: 'datumInvoice', sortable: false, align: 'center' },
      { text: this.$t('gross_amount'), value: 'bruttobetrag', sortable: false, align: 'right' },
    ];
    return headers;
  }

  //#region Logic related to `status` dialog

  get statuses() {
    return {
      1: { text: this.$t('status_chip.new'), class: 'none' },
      2: { text: this.$t('status_chip.book'), class: 'green' },
      3: { text: this.$t('status_chip.not_book'), class: 'red' },
      4: { text: this.$t('status_chip.rejected'), class: 'red' },
      5: { text: this.$t('status_chip.posted'), class: 'green' },
      6: { text: this.$t('status_chip.error'), class: 'orange' },
    };
  }

  get apiStatuses() {
    return [4, 5, 6];
  }

  updateDocumentFormData(document: any) {
    logger.log('result updateDocumentFormData (after `status dialog` update):>> ', document);
    const responseDocument = document;
    this.zrDocument.bonusgutschrift = document.bonusgutschrift;
    this.zrDocument.skontierfaehig = document.skontierfaehig;
    this.zrDocument.faelligkeit = document.faelligkeit;

    this.zrDocument.status_Freigabe = responseDocument.status_Freigabe;
    this.zrDocument.bearbeit_Am = responseDocument.bearbeit_Am;
    this.zrDocument.bearbeit_Von = responseDocument.bearbeit_Von;
    if (!this.zrDocument.kommission_Nummer) {
      this.zrDocument.kommission_Nummer = responseDocument.kommission_Nummer;
    }

    this.zrDocument.text_nichtbuchen = responseDocument.text_nichtbuchen;
  }

  showMenu = false;
  closeMenu() {
    this.showMenu = false;
  }

  //#endregion

  // use `19` for `steursatz` if it is NULL on backend
  get betragNettoNumber() {
    // return this.zrDocument.betrag_Brutto / (1 + this.zrDocument.steursatz);

    if (this.zrDocument.betrag_Netto) {
      return this.zrDocument.betrag_Netto;
    }
    // should `Round` (not `Trunc`) to avoid 1 cent difference with actual `Beleg` document
    const result = NumberUtils.round(((this.zrDocument.betrag_Brutto / (1 + this.taxRate / 100)) * 100) / 100, 2); // return 2 decimal digits with rounding
    return result;
  }
  get betragNetto() {
    // return this.zrDocument.betrag_Brutto / (1 + this.zrDocument.steursatz);
    return (
      this.betragNettoNumber.toLocaleString(this.$i18n.locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) +
      ' ' + this.getCurrencySymbol(this.zrDocument.waehrung)
    );
  }

  get taxAmount() {
    if (isNaN(this.zrDocument.betrag_Brutto - this.betragNettoNumber)) {
      return '';
    }
    const bruttoNettoDiff = NumberUtils.differenceDecimals(this.zrDocument.betrag_Brutto, this.betragNettoNumber);

    const taxNumber = Math.trunc(bruttoNettoDiff * 100) / 100; // return 2 decimal digits without rounding
    return taxNumber.toLocaleString(this.$i18n.locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + ' ' + this.getCurrencySymbol(this.zrDocument.waehrung);
  }

  get localizedBetragBrutto() {
    return (
      this.zrDocument.betrag_Brutto?.toLocaleString(this.$i18n.locale, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + ' ' + this.getCurrencySymbol(this.zrDocument.waehrung)
    );
  }

  // use `19` for `steursatz` if it is NULL on backend
  get taxRate() {
    return this.zrDocument.steursatz === null || this.zrDocument.steursatz === undefined
      ? 19
      : this.zrDocument.steursatz;
  }

  get localizedTaxRate() {
    return this.taxRate.toLocaleString(this.$i18n.locale) + ' %'; // || (19).toLocaleString(this.$i18n.locale);
  }

  private async updateCommission(item: any) {
    if (!this.zrDocument.kommission_Nummer?.trim()) {
      return;
    }

    let payload: any = {
      id: this.zrDocument.id,
      kommission_Nummer: this.zrDocument.kommission_Nummer,
    };

    this.isLoading = true;
    logger.log(payload);
    await this.actionUpdateZRDocumentCommission(payload)
      .then((result: any) => {
        const responseDocument = result.result;
        this.zrDocument.bearbeit_Am = responseDocument.bearbeit_Am;
        this.zrDocument.bearbeit_Von = responseDocument.bearbeit_Von;
        this.originalCommission = responseDocument.kommission_Nummer; // make `save commission` button disappear
      })
      .catch((err: any) => {
        logger.error(err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  async onClickedUpdate() {
    const result = await this.addEditZrDocumentForm.validate();
    if (result) {
      this.isLoading = true;

      this.zrDocument.id = this.zrDocumentId;
      await this.actionUpdateZRDocument(this.zrDocument)
        .then((response: any) => {
          // if (this.isZrDocumentCreate) {
          //   this.$router.push({
          //     name: ROUTES.list,
          //     params: { saveListId: response.id },
          //   });
          // }
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  goToHome() {
    this.$router.push({
      name: ROUTES.home,
    });
  }

  //#region Next/previous logic
  get currentIdPosition() {
    return this.documents.findIndex((x: any) => x.id == this.zrDocumentId);
  }

  get isFirstDocument() {
    // console.log('this.documents :>> ', this.documents);
    // console.log(
    //   'this.documents ID :>> ',
    //   this.documents.findIndex((x: any) => x.id == this.zrDocumentId)
    // );
    return this.currentIdPosition == 0;
  }

  get pdfPages() {
    let pages = (document.querySelector(`iframe#${this.belegeType}`) as any)?.contentDocument.querySelectorAll(
      `.page`
    )?.length;
    return pages;
    // iframe.contentWindow.document.querySelectorAll('.page').length
  }

  get isLastDocument() {
    return this.currentIdPosition == this.documents.length - 1;
  }

  get hasIframeWithDocument() {
    return this.isRecordLoaded && document.querySelector(`iframe#${this.belegeType}`);
  }

  async screenshot(page = 1) {
    if (!this.hasIframeWithDocument) {
      return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAQAAAAnOwc2AAAAD0lEQVR42mNkwAIYh7IgAAVVAAuInjI5AAAAAElFTkSuQmCC'; // white transparent square 10x10 pixels (generated here https://png-pixel.com/)
    }

    let fstPageDataUrl = '';
    let iframe1stPage: any = (
      document.querySelector(`iframe#${this.belegeType}`) as any
    ).contentDocument!.querySelector(`#viewer > div[data-page-number="${page}"] .canvasWrapper`)!;
    // const iframe1stPage: any = new_el.contentDocument!.querySelector('#viewer > div[data-page-number="1"] .canvasWrapper')!;
    // console.log('iframe1stPage :>> ', iframe1stPage);
    // (GPS-128) if image is empty try to read it again
    if (!iframe1stPage) {
      const pdfjs: any = (document.querySelector(`iframe#${this.belegeType}`) as any)!.contentWindow
        .PDFViewerApplication;
      pdfjs.page = page;
      iframe1stPage = (document.querySelector(`iframe#${this.belegeType}`) as any).contentDocument!.querySelector(
        `#viewer > div[data-page-number="${page}"] .canvasWrapper`
      )!;
      await delay(1500);
      console.log('iframe1stPage  again:>> ', iframe1stPage);
    }

    await html2canvas(iframe1stPage, {
      allowTaint: true,
      useCORS: true,
    })
      .then((canvas: any) => {
        // It will return a canvas element
        let image = canvas.toDataURL('image/png', 0.75);
        fstPageDataUrl = image;
      })
      .catch((e: any) => {
        // Handle errors
        console.log(e);
      });
    return fstPageDataUrl;
  }

  goToPrev() {
    const prevId = this.documents[this.currentIdPosition - 1].id;

    this.$router.push({
      name: ROUTES.zrDocument,
      params: { zrDocumentId: prevId },
    });
    this.getZrDocument();
  }

  goToNext() {
    const nextId = this.documents[this.currentIdPosition + 1].id;

    this.$router.push({
      name: ROUTES.zrDocument,
      params: { zrDocumentId: nextId },
    });
    this.getZrDocument();
  }
  //#endregion

  // (GSP-128) if not scroll/navigate through all pages, then missing pages can occur (not empty but missin, like 3-4 pages report for 7 pages PDF)
  preloadAllPagesForPdfReport() {
    const pdfjs: any = (document.querySelector(`iframe#${this.belegeType}`) as any)!.contentWindow.PDFViewerApplication;
    console.log('documentLoaded pdfjs :>> ', pdfjs);
    let pages = (document.querySelector(`iframe#${this.belegeType}`) as any)?.contentDocument.querySelectorAll(
      `.page`
    )?.length;

    if (!pdfjs || !pages) {
      setTimeout(() => {
        this.preloadAllPagesForPdfReport();
      }, 2000);
      return;
    }
    pages = pages > 10 ? 10 : pages;
    for (let index = 1; index <= pages; index++) {
      setTimeout(() => {
        pdfjs.page = index;
        // console.log('index :>> ', index);
        if (index == pages) {
          this.isPdfLoaded = true;
          // console.log('done :>> ');
        }
      }, index * 400);
    }
  }

  documentLoaded() {
    // need timeouts to wait
    setTimeout(() => {
      this.preloadAllPagesForPdfReport();
    }, 1000);
  }

  async processArray() {
    let array = [];
    let pages = this.pdfPages > 10 ? 10 : this.pdfPages;
    for (let index = 1; index <= pages; index++) {
      console.log('index :>> ', index);
      let data = await this.screenshot(index);
      //  console.log('data :>> ', data.substring(0,50));
      if (data) {
        //  array.push(index.toString());
        array.push(data);
      }
    }
    return array;
  }

  async pdfDownload() {
    this.isGeneratingPdfReport = true;
    this.changePdfScale();
    delay(1200).then(async (result) => {
      let pdfScreenDataUrl = await this.screenshot();
      let pdfScreenDataArray: any = [];
      if (this.pdfPages > 1) {
        console.log('this.pdfPages :>> ', this.pdfPages);
        pdfScreenDataArray = await this.processArray();
      }

      delay(1200).then(() => {
        let payload = this.zrDocument;
        payload.pdfScreen = pdfScreenDataUrl;
        payload.pdfScreenArr = pdfScreenDataArray;
        payload.DateTimeTitle =
          this.$i18n.locale == 'de' ? new Date().toLocaleString('de') : new Date().toLocaleString('en');
        payload.lang = this.$i18n.locale;
        this.actionGetZrDocumentsPdfReportView(payload).finally(() => {
          this.isGeneratingPdfReport = false;
        });

        delay(500).then(() => {
          this.returnInitialPdfScale();
        });
      });
    });
  }

  private pdfScaleInitialValue = '';
  private pdfScaleElem: any = {};
  changePdfScale() {
    if (!this.hasIframeWithDocument) return;

    const size200percent: any = (document.querySelector(
      `iframe#${this.belegeType}`
    ) as any)!.contentDocument!.querySelector('#scaleSelect')!;
    // console.log('size200percent :>> ', size200percent);
    if (size200percent) {
      this.pdfScaleElem = size200percent;
      this.pdfScaleInitialValue = size200percent.value;

      size200percent.value = 1.5;
      size200percent.dispatchEvent(new Event('change'));
    }
  }
  returnInitialPdfScale() {
    if (!this.hasIframeWithDocument) return;

    this.pdfScaleElem.value = this.pdfScaleInitialValue;
    this.pdfScaleElem.dispatchEvent(new Event('change'));
  }

  getTitle(value: string) {
    if (value != null && value != '-') {
      return this.$t('edited_by') + ':  ' + value + ', ';
    } else {
      return '';
    }
  }

  submitForm(e: any) {
    e.preventDefault(); // (GSP-076) prevent reload page when click on any button in PDF preview (vue-pdf-app) toolbar
  }

  getCurrencySymbol(currency: any) {
    // (GSP-212) move `toLowerCase` logic here to solve `currency` NULL error from HTML
    if (currency) {
      currency = currency.toLowerCase();
    }

    let currencySymbol: string = '';
    if (currency == 'euro' || currency == 'eur' || currency == '' || !currency) {
      currencySymbol = '€';
    } else if (currency == 'chf') {
      currencySymbol = 'CHF';
    } else if (currency == 'usd') {
      currencySymbol = '$';
    }

    return currencySymbol;
  }
}
